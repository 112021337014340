import { Box, Grid, Paper, Typography } from "@mui/material"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { blueGrey } from "@mui/material/colors"
import { graphql } from "gatsby"
import { Button } from "gatsby-material-ui-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout"
import SectionWrapper from "../components/sectionWrapper"
import Seo from "../components/seo"

const NewsPage = ({ pageContext: { originalPath }, data }) => {
  const { t } = useTranslation()
  const blogPosts = data.allWpPost.nodes

  return (
    <Layout title={t("news")} originalPath={originalPath}>
      <Seo title={t("news")} />
      <SectionWrapper light>
        <Typography variant="h2" component="h1">
          News
        </Typography>
        {blogPosts.map(({ title, date, excerpt, slug, featuredImage }) => (
          <Box py={1} key={title}>
            <Paper variant="outlined" style={{ backgroundColor: blueGrey[50] }}>
              <Grid container>
                <Grid item xs={12} md={4} sx={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                  <GatsbyImage image={getImage(featuredImage.node.localFile)} alt={title} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box
                    p={2}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height={1}
                  >
                    <Box>
                      <Typography variant="overline" color="textSecondary">
                        {date}
                      </Typography>
                      <Typography variant="h5" gutterBottom>
                        {title}
                      </Typography>
                      <Typography>
                        <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        to={`/news/${slug}`}
                        endIcon={<ArrowForwardIcon />}
                      >
                        Keep reading
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        ))}
      </SectionWrapper>
    </Layout>
  )
}

export default NewsPage

export const pageQuery = graphql`
  query {
    allWpPost(
      sort: { fields: date, order: DESC }
      ) {
      nodes {
        title
        slug
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        excerpt
      }
    }
  }
`